import * as React from "react"

// markup
const ChitChatsHelpPage = () => {
  return (
    <main>
      <h1>ChitChats Help</h1>
      <br/>
      <p>I'm thinking of a word...</p>
      <p>Take turns guessing the word with a friend, and I will tell you if it's before or after my word</p>
      <p>First to guess my word, wins</p>
    </main>
  );
}

export default ChitChatsHelpPage